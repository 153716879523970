<template>
	<master-layout
		smallTitle="Statistiche"
		bigTitle="Rete"
	>
		<ion-grid class="ion-padding-top ion-margin-top">
			<div
				v-for="item in items"
				:key="item.id"
				class="mb-4 item-card cursor-pointer"
				@click="goToUtenteOrdini(item.idAgente)"
			>
				<ion-grid class="w-full">
					<ion-row>
						<ion-col size="12">
							<span class="font-bold">{{ item.nomeAgente }}</span>
						</ion-col>
						<ion-col size="8">
							<span>Valore ordini:</span>
						</ion-col>
						<ion-col size="4">
							<span> {{ item.valoreOrdini }} €</span>
						</ion-col>
						<ion-col size="8">Ordini confermati:</ion-col>
						<ion-col size="4">{{ item.ordiniConfermati }}</ion-col>
						<ion-col size="8">Ordini da confermare:</ion-col>
						<ion-col size="4">{{ item.ordiniDaConfermare }}</ion-col>
					</ion-row>
					<ion-row>
						<ion-col cols="12">
							<kpi-graph
								:prop_dataSet="[
									{ data: item.kpiHistoryValue, color: primaryColor },
									{ data: item.kpiHistoryGoal, color: secondaryColor },
								]"
								:labelsValues="item.kpiHistoryLabel"
								:prop_options="{ fontColor: 'black', colorXY: 'black' }"
							></kpi-graph>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
		</ion-grid>
	</master-layout>
</template>

<script setup>
	import { IonGrid, IonRow, IonCol } from "@ionic/vue";
	import KpiGraph from "../../components/KpiGraph.vue";
	import { ref, onMounted, getCurrentInstance } from "vue";
	import ApiService from "../../common/service.api";
	import { useRouter } from "vue-router";
	const router = useRouter();

	let app = getCurrentInstance();
	let whappyClientConfig =
		app.appContext.config.globalProperties.whappyClientConfig;
	const primaryColor = whappyClientConfig.primaryColor;
	const secondaryColor = whappyClientConfig.secondaryColor;
	function getData() {
		const url = `Ecommerce/getStats`;
		ApiService.get(url).then((res) => {
			items.value = res.data;
		});
	}

	function goToUtenteOrdini(idUtente) {
		router.push({ name: "lista-ordini", params: { idUtente } });
	}

	const items = ref([]);

	onMounted(() => {
		getData();
	});
</script>

<style scoped>
	.item-card {
		border: 3px solid var(--ion-color-primary);
		overflow: hidden;
		width: 100%;
		min-height: 120px;
		background: var(--client-ground1-color);
		border-radius: var(--ion-border-radius);
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03),
			0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		padding: 1rem 2rem;
	}
</style>
